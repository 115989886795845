var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "add-member",
      attrs: { title: "Add New Members" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible"
      }
    },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { lg: 24, md: 24, sm: 24, xs: 24 } },
            [
              _c(
                "a-form",
                { attrs: { form: _vm.form }, on: { submit: _vm.addMember } },
                [
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "mail" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "work_email",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Email is required"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                  'work_email',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Email is required'\n                      }\n                    ]\n                  }\n                ]"
                                  }
                                ],
                                attrs: {
                                  type: "email",
                                  placeholder: "Enter email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "user-add" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "first_name",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "First name is required"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                  'first_name',\n                  {\n                    rules: [\n                      { required: true, message: 'First name is required' }\n                    ]\n                  }\n                ]"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter first name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "user-add" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "last_name",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Last name is required"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                  'last_name',\n                  {\n                    rules: [\n                      { required: true, message: 'Last name is required' }\n                    ]\n                  }\n                ]"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter Last name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "mobile" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "phone_no",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Phone is required"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                  'phone_no',\n                  {\n                    rules: [{ required: true, message: 'Phone is required' }]\n                  }\n                ]"
                                  }
                                ],
                                attrs: {
                                  type: "number",
                                  min: "0",
                                  placeholder: "Enter phone number"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "environment" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "address_1",
                                      {
                                        rules: [
                                          {
                                            required: true,
                                            message: "Address is required"
                                          }
                                        ]
                                      }
                                    ],
                                    expression:
                                      "[\n                  'address_1',\n                  {\n                    rules: [\n                      { required: true, message: 'Address is required' }\n                    ]\n                  }\n                ]"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "Enter address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "user" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["emergency_phone"],
                                    expression: "['emergency_phone']"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "Emergency phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-left": "5px" },
                          attrs: { type: "flex", align: "middle", gutter: 1 }
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { xs: 2 } },
                            [_c("a-icon", { attrs: { type: "calendar" } })],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { xs: 22 } },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: ["dob"],
                                    expression: "['dob']"
                                  }
                                ],
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "DD/MM/YYYY",
                                  placeholder: "Date of birth"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "gx-text-right",
                          staticStyle: { "margin-top": "0px" },
                          attrs: { type: "block", gutter: 1 }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                "inline-block": "",
                                type: "primary",
                                "html-type": "submit"
                              }
                            },
                            [_vm._v("Add Members")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }